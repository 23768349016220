import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";
import { observer } from "mobx-react";
import { Store } from "../../networking/stores/Store";
import { MStore } from "../../networking/stores/MainStore";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";

const UserProfile = ({ pathname }) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li className="gx-text-danger" onClick={() => dispatch(userSignOut())}>
        <IntlMessages id="cikisyap" />
      </li>
    </ul>
  );

  const user = MStore.user;

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        onVisibleChange={setVisible}
        visible={visible}
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          src={"https://cyberistanbul.com/assets/img/logo2.png"}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {user?.nickname}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default observer(UserProfile);
