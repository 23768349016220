import { makeAutoObservable, configure } from "mobx";

configure({enforceActions: "never"})

class InstantStoreC {
   loading = false;
   modal = {};
   scroll = null;

   constructor(){
    makeAutoObservable(this);
   }

   setLoading(data) {
    this.loading = data;
  }
   setModal(data) {
    this.modal = data;
  }
   changeModal(data) {
    this.modal = { ...this.modal, ...data };
  }
   setScroll(data) {
    this.scroll = data;
  }
}

export const Store = new InstantStoreC();
