import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { MailFilled, LockFilled } from "@ant-design/icons";
import { post } from "../../networking/Server";
import { useIntl } from "react-intl";
import { userSignIn } from "../../appRedux/actions";
import { success, error } from "../../functions/toast";

const SignIn = ({ history }) => {
  const intl = useIntl();

  const [loading, setLoading] = useState(false);

  const token = useSelector(({ auth }) => auth.token);

  const dispact = useDispatch();

  const onLogin = (values) => {
    setLoading(true);
    post("admin/login", values, false).then((res) => {
      setLoading(false);

      if (!res.error) {
        success();
       
        dispact(userSignIn(res.accessToken, res.user));
        const last_path = localStorage.getItem("last_path");
        if (last_path) {
          history.push(last_path);
          localStorage.setItem("last_path", "");
        }
      } else {
       error(intl.formatMessage({ id: res.error }));
      }
    });
  };

  useEffect(() => {
    if (token) {
      history.push("/");
    }
  }, [token, history]);




  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content gx-align-items-center gx-align-self-center">
            <div className="gx-app-logo-wid">
              <div
                style={{
                  height: 120,
                  width: 120,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 1000,
                  backgroundColor: "#f0f0f0",
                }}
              >
                <img
                  height={80}
                  width={80}
                  alt="example"
                  src={require("assets/images/ci-logo2.png")}
                />
              </div>
            </div>
          </div>
          <div className="gx-app-login-content">
            <h1
              className="gx-mb-5 gx-mt-4 gx-text-center"
              style={{ fontWeight: 500 }}
            >
              NefPoker <IntlMessages id="giris" />
            </h1>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onLogin}
              className="gx-signin-form gx-form-row0 gx-text-center"
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "gecersizbilgi" }),
                  },
                ]}
                name="nickname"
              >
                <Input
                  allowClear
                  bordered={false}
                  prefix={<MailFilled className="gx-text-secondary" />}
                  style={{ backgroundColor: "#f5f5f5", borderRadius: 20 }}
                  placeholder={intl.formatMessage({ id: "email" })}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: "gecersizbilgi" }),
                  },
                ]}
                name="pass"
              >
                <Input
                  type="password"
                  allowClear
                  bordered={false}
                  prefix={<LockFilled className="gx-text-secondary" />}
                  style={{ backgroundColor: "#f5f5f5", borderRadius: 20 }}
                  placeholder={intl.formatMessage({ id: "sifre" })}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  style={{ width: "100%", borderRadius: 20 }}
                  type="primary"
                  className="gx-mb-0"
                  htmlType="submit"
                >
                  <IntlMessages id="girisyap" />
                </Button>
              </Form.Item>
              {/*<Form.Item>
                <Button
                  style={{width:'100%',borderRadius:20}}  className="gx-mb-0 gx-text-primary"
                  onClick={()=>{
                    history.push("register")
                  }}
                >
                  Üye Ol
                </Button>
              </Form.Item>*/}
              <div
                onClick={() => {
                  history.push("register");
                }}
                className="gx-text-primary gx-fs-sm gx-font-weight-bold gx-mt-4 gx-mb-4 gx-pointer"
              >
                <IntlMessages id="personelhesabiolustur" />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
