const languageData = [
  {
    languageId: 'turkish',
    locale: 'tr',
    name: 'Türkçe',
    icon: 'tr'
  },
  

];
export default languageData;
