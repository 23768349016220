import React from "react";

export const VERSION = "1.0.0";
export const API_VERSION = "1.0.0";

const pages = [
  {
    name: "homepage",
    path: "home",
    icon: "dasbhoard",
    nav: true,
    page: import("../routes/Home"),
  },
  {
    name: "users",
    path: "user",
    icon: "user",
    nav: true,
    page: import("../routes/Users"),
  },
  {
    name: "Rapor",
    path: "report",
    icon: "data-display",
    nav: true,
    page: import("../routes/Reports"),
  },
  {
    name: "Bildirimler",
    path: "notification",
    icon: "chat-bubble",
    nav: true,
    page: import("../routes/Notifications"),
  },
];

export function getPages(nav) {
  return pages.filter((e) => (nav ? e.nav == nav : true));
}
