import { observable, action, makeAutoObservable, configure } from "mobx";
import { create, persist } from "mobx-persist";

configure({enforceActions: "never"})

class MainStoreC {
  @persist("object") language = {};
  @persist("object") user = {};

  constructor(){
    makeAutoObservable(this)
  }

setLanguage(data) {
    this.language = data;
  }
  setUser(data) {
    this.user = data;
  }
}

const hydrate = create({});

export const MStore = new MainStoreC();

hydrate("MainStore", MStore)
  .then(() => console.info("MStore hydrated"))
  .catch((e) => console.error("Store Error: ", e));