import {Store} from "../../networking/stores/Store";
import React from "react";
import {observer} from "mobx-react";
import {Drawer, Modal} from "antd";
import "./custom-modal.css";

const CustomModal = ()=>{
    return(
      <div>
        {
          Store.modal.center?
            <Modal
              style={{transition: 'width .2s'}}
              width={Store.modal.width}
              footer={null}
              title={Store.modal.title}
              visible={Store.modal.title}
              onCancel={()=>{
                Store.setModal({})
              }}
            >
              <div style={{transition: 'height .2s',maxHeight:'70vh',overflowY: 'scroll'}}>
                {Store.modal.child}
              </div>

            </Modal>:
            <Drawer
              className="drawer-width"
              title={Store.modal.title}
              placement="right"
              onClose={()=>{
                Store.setModal({})
              }}
              visible={Store.modal.title}
            >
              <div className="gx-mt-3"/>
              {Store.modal.child}
            </Drawer>
        }
      </div>
    )
}
export default observer(CustomModal)
